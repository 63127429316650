import React from 'react'

import Layout from '../components/layout'

import IconLink from './../components/iconLink/IconLink'
import HalfImage from './../components/halfImage/HalfImage'

import headerImage from './../images/header/leda-homecare-respite-care.png'

import arrowHeart from './../images/icons/arrow-heart.svg'
import houseHeart from './../images/icons/house-heart.svg'
import humanHeart from './../images/icons/human-heart.svg'
import arrowHeartCircle from './../images/icons/arrow-heart-circle.svg'
import houseHeartCircle from './../images/icons/house-heart-circle.svg'
import humanHeartCircle from './../images/icons/human-heart-circle.svg'

const Services = () => (
  <Layout>
    <div className="services">
      <div
        className="header-image"
        style={{ backgroundImage: `url(${headerImage})` }}
      />
      <div className="container" style={{ marginTop: '100px' }}>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="twelve columns">
            <h2 className="center-text">Types of Care</h2>
            <p className="center-text">
              At Leda Homecare Ltd, we know that different people need different
              types of care and different levels of care. We therefore offer a
              range of products which can be discussed and adapted to suit every
              client. We ensure that all our clients are the decision makers and
              we work with them to make their day to day task as easy as
              possible
            </p>
            <p className="center-text">
              Our full range of services are available from half an hour to 24
              hours a day, 7 days a week, 365 days a year.
            </p>
            <p className="center-text">
              We believe in honesty, integrity, pride in what we do and respect
              for those we do it for. What matters is that the homecare services
              you receive are the best fit for your lifestyle and specific to
              your home – no matter how much or how little you require.
            </p>
            <p className="center-text">
              Indeed, with discretion and courtesy, our care team will make a
              point of getting to know you and your life in your home. That way,
              you’ll get support that’s right for you, in the place where you’re
              most comfortable.
            </p>
            <p className="center-text">
              If you would like to know more about any of our care programmes or
              wish to discuss a bespoke care programme, please feel free to call
              us on 01909 512550 or email us on{' '}
              <a href="mailto:admin@ledahomecare.co.uk">
                admin@ledahomecare.co.uk.
              </a>{' '}
              We can offer FREE home consultations and Information packs
              available on request.
            </p>
          </div>
        </div>
        <div className="row mobile-only">
          <div className="four columns">
            <IconLink
              icon={arrowHeart}
              title="Respite Care"
              link="#respiteCare"
            />
          </div>
          <div className="four columns">
            <IconLink icon={houseHeart} title="Help at Home" link="#homeCare" />
          </div>
          <div className="four columns">
            <IconLink
              icon={humanHeart}
              title="Younger Adults"
              link="#youngCare"
            />
          </div>
        </div>
      </div>
      <HalfImage
        image={arrowHeartCircle}
        imagePosition="left"
        icon
        background
        text={[
          <div
            key="hidden"
            id="respiteCare"
            style={{
              height: '10px',
              width: '10px',
              position: 'absolute',
              top: '-70px',
            }}
          />,
          <h2 key="0">Respite Care</h2>,
          <p key="1">
            Doing your best for your family comes as second nature, but there
            are times when an extra pair of hands would be very welcome. Our
            respite care is as flexible as you need it to be. We can offer
            holiday cover or just a few hours, we will work with you to create a
            care package which works for both you and the person you are caring
            for.
          </p>,
          <p key="2">
            The responsibility of being a sole carer can be tiring, particularly
            when your loved one has long term and complex health needs. We
            recognise that you need a break but you need to be confident that
            the individual who is standing in for you so you can go away in the
            knowledge your loved one is in the very best hands.
          </p>,
        ]}
      />
      <HalfImage
        image={houseHeartCircle}
        imagePosition="right"
        icon
        mobileFlip
        background
        text={[
          <div
            key="hidden"
            id="homeCare"
            style={{
              height: '10px',
              width: '10px',
              position: 'absolute',
              top: '-70px',
            }}
          />,
          <h2 key="0">Help at Home</h2>,
          <p key="1">
            People often want to stay at home as they grow older. However,
            living at home, especially on your own can become more and more
            difficult depending on the individuals health and levels of ability.
            At Leda Homecare, we see that often the help needed to retain this
            independance. We offer:
          </p>,
          <ul key="2">
            <li>Cleaning, ironing and preparing meals</li>
            <li>Washing, dressing or shaving</li>
            <li>Shopping, collecting prescriptions</li>
            <li>Medication assistance</li>
            <li>Company and help whilst recovering from illness</li>
            <li>Assistance with getting up and going to bed</li>
            <li>Accompanying you to appointments or social activities.</li>
          </ul>,
        ]}
      />
      <HalfImage
        image={humanHeartCircle}
        imagePosition="left"
        icon
        background
        text={[
          <div
            key="hidden"
            id="youngCare"
            style={{
              height: '10px',
              width: '10px',
              position: 'absolute',
              top: '-70px',
            }}
          />,
          <h2 key="0">Younger Adults</h2>,
          <p key="1">
            Leda Homecare Ltd can provide specialist care staff to look after
            anyone over the age of 18.
          </p>,
          <p key="2">
            Our dedicated carers offer our clients and their family the
            friendly, professional support they need whenever they need it.
          </p>,
          <p key="3">Young adults in need of our services may include:</p>,
          <ul key="4">
            <li>The learning impaired</li>
            <li>Those with any manner or severity of physical disabilities</li>
            <li>Clients recovering from major surgery</li>
            <li>Those convalescing after an accident</li>
          </ul>,
        ]}
      />
    </div>
  </Layout>
)

export default Services
